.orders {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    box-sizing: border-box;

    .management {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
        box-sizing: border-box;
    }

    .steps-tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }
}

.table-text-icon {
    display: flex;
    gap: 20px;
    cursor: pointer;
    text-align: left;
}

.step-footer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    // padding-top: 16px;

    .save-and-cancel {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}
