.fixed-container {
    position: fixed;
    height: 100%;
    width: 100%;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    bottom: 0;
    // z-index: 1000;

    .routes-modal {
        display: flex;
        flex-direction: column;
        padding: 0 40px;
        border-radius: 6px;
        background: var(--white);
        box-sizing: border-box;
        box-shadow: 0 4px 15px 0 rgba(44, 63, 88, 0.35);
        position: relative;

        .close-icon {
            position: absolute;
            display: flex;
            top: -5px;
            left: -5px;
            width: 34px;
            height: 34px;
            box-shadow: 0 4px 15px 0 rgba(44, 63, 88, 0.35);
            border-radius: 6px;
            background: var(--white);
            border: none;
        }

        .modal-wrapper {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }

        .dynamic-modal-header {
            font-family: Rubik-Medium;
            font-size: 28px;
            font-weight: 500;
            line-height: 1.25;
            color: var(--black);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }

        .dynamic-modal-content {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            box-sizing: border-box;
            white-space: pre-line;
            align-items: center;
            text-align: center;
            max-height: 600px;
            overflow: auto;
            padding: 4px;
        }
    }
}

.choose-modal {
    display: flex;
    height: 150px;
    width: 300px;
}

.report-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 0 24px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--title-grey-color);
    max-height: 500px;

    .report-title {
        display: flex;
        font-size: 18px;
        font-weight: 500;
        text-align: right;
        color: var(--title-grey-color);
    }
}

.report-table {
    padding: 24px;
    overflow: auto;
    height: 50%;
    width: 100%;

    th,
    tr,
    td,
    body {
        border: solid 1px var(--black);
        padding: 4px 24px;
    }
}
